<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            <!-- <v-icon @click="$router.go(-1)">mdi-keyboard-backspace</v-icon> -->
            Users
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
             <v-col>
              <download-excel :data="json_data" :fields="json_fields">
                <span style="font-size:16px; cursor:pointer;"> Download Excel</span>
              </download-excel></v-col
            >
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="user"
            :items-per-page="count"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:top>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this Client?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm(true)"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.firstname`]="{ item }">
              <span>{{ item.firstname }}</span>
              <span> {{ item.lastname }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="userinfo(item._id)">
                mdi-eye
              </v-icon>
              <v-icon small class="mr-2" @click="edit(item._id)">
                mdi-pencil
              </v-icon>
              <!-- <v-icon small @click="deleteItem(item._id)"> mdi-delete </v-icon> -->
            </template>
            <!-- <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template> -->
          </v-data-table>
          <div class="pt-2" v-if="pages > 1">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#FF0000"
            ></v-pagination>
          </div>
          <!-- <div class="blue-grey lighten-5 elevation-1"  >
            <v-btn @click="$router.go(-1)" outlined
      color="black" class="mt-5 mb-5" >Back</v-btn>
          </div> -->
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      dialogDelete: false,
      ServerError: false,
      search: "",
      currentPage: 1,
      pages: 0,
      totalData: 0,
      count: 20,
      totalRows: 0,
      headers: [
        { text: "Name", value: "firstname" },
        { text: "Username", value: "username" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Actions", value: "actions", sortable: false },
      ],
       json_fields: {
        FirstName: "firstname",
        LastName: "lastname",
        Username:"username",
        Email: "email",
        Phone: "phone",
        Gender:"gender",
        Type:"type",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      user: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    search() {
      this.getData();
    },
  },
  methods: {
    deleteItem(val) {
      this.dialogDelete = true;
      var val1 = this.deleteItemConfirm();
      if (val1) {
        axios({
          url: "/user/remove",

          method: "GET",
          params: {
            id: val,
          },
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.getData();
            } else {
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        this.closeDelete();
      }
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/user/allClients",

        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          keyword: this.search,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
           this.json_data = this.user;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    userinfo(uid) {
      this.$router.push({ name: "userDetails", params: { userid: uid } });
    },
    edit(uid) {
      this.$router.push({ name: "edit", params: { userid: uid } });
    },
  },
};
</script>

<style>
</style>